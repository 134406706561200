







import { Component, Vue } from 'vue-property-decorator'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NoMessages>({
  name: 'NoMessages'
})
export class NoMessages extends Vue {
}

export default NoMessages
